/** @jsx jsx */
import { jsx } from "theme-ui"
import PageStructure from "../../components/PageStructure"
import Box from "../../components/Atomic/Box"
import Flex from "../../components/Atomic/Flex"
import Spacing from "../../components/Atomic/Spacing"

import { Grid } from "theme-ui"

import format from "date-fns/format"

// import React from "react"
import { graphql, Link } from "gatsby"
import Text from "../../components/Atomic/Text"

export default function blog({ data, location }) {
  const posts = data?.allContentfulPost?.nodes
  return (
    <PageStructure
      pageTitle={`Blog`}
      metaContent={`My blog documenting my progress in programming, art and any other skill`}
      location={location}
    >
      <Flex sx={{ justifyContent: "center", flexDirection: "column" }} p={"sm"}>
        <Grid
          as={`ul`}
          sx={{
            // display: "flex",
            // flexDirection: "column",
            margin: 0,
            padding: 0,
            listStyle: "none",
            width: ["100%", null, null, "75%"],
          }}
          columns={[1, 1, 2]}
          gap={10}
        >
          {posts?.map((post, idx) => {
            const firstParagraph = JSON.parse(post.content.raw)
              .content.find(content => content.nodeType === "paragraph")
              .content.find(content => content.nodeType === "text").value
            const excerpt =
              firstParagraph.length > 50
                ? `${firstParagraph.slice(0, 47)}...`
                : firstParagraph
            return (
              <li
                key={idx}
                sx={{
                  // borderColor: "greyScale.4",
                  borderColor: "pink",
                  padding: "xs",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderRadius: "md",
                  marginBottom: 0,
                }}
              >
                <Flex
                  sx={{
                    color: "greyScale.1",
                    textDecoration: "none",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>{post.title}</Box>
                  <Box>{format(new Date(post.publishDate), "yy-MM-dd")}</Box>
                </Flex>
                <Spacing py={"sm"}>
                  <Text as={"p"} align={"left"}>
                    {excerpt}
                  </Text>
                </Spacing>
                <Box
                  as={Link}
                  to={`${post.slug}`}
                  sx={{
                    textDecoration: "none",
                    color: "greyScale.1",
                    borderRadius: "sm",
                    borderStyle: "solid",
                    // borderColor: "greyScale.2",
                    borderColor: "pink",
                    borderWidth: 1,
                    padding: "xs",
                    // backgroundColor: "greyScale.6",
                    // "&:hover": { backgroundColor: "greyScale.5" },
                    backgroundColor: "background",
                    "&:hover": { backgroundColor: "lightBackground" },
                    marginTop: "xs",
                    display: "inline-block",
                  }}
                >
                  <Text>Read more</Text>
                </Box>
              </li>
            )
          })}
        </Grid>
      </Flex>
    </PageStructure>
  )
}

export const query = graphql`
  query blogQuery {
    allContentfulPost(sort: { order: DESC, fields: publishDate }) {
      nodes {
        content {
          raw
        }
        title
        publishDate
        slug
      }
    }
  }
`
