import Box from "../Box"

import React from "react"

export default function Spacing({
  mt,
  mr,
  mb,
  ml,
  pt,
  pr,
  pb,
  pl,
  mx,
  my,
  px,
  py,
  m,
  p,
  children,
}) {
  return (
    <Box
      sx={{
        marginTop: mt ?? my ?? m ?? 0,
        marginRight: mr ?? mx ?? m ?? 0,
        marginBottom: mb ?? my ?? m ?? 0,
        marginLeft: ml ?? mx ?? m ?? 0,
        paddingTop: pt ?? py ?? p ?? 0,
        paddingRight: pr ?? px ?? p ?? 0,
        paddingBottom: pb ?? py ?? p ?? 0,
        paddingLeft: pl ?? px ?? p ?? 0,
      }}
    >
      {children}
    </Box>
  )
}
